export default [
  {
    path: '/deactivated',
    name: 'deactivated',
    component: () => import('Streamer/pages/StreamerDeactivated'),
    meta: { title: 'Uplify' },
  },
  {
    path: '/ref/:token',
    name: 'referral-check',
    component: () => import('Streamer/pages/SreamerReferralCheck.vue'),
    meta: { title: 'Creator Log In - Uplify' },
  },
  {
    path: '/onboarding',
    name: 'tipalti-onboarding',
    component: () => import('Streamer/pages/StreamerTipalti'),
    meta: { title: 'Tipalti - Uplify' },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('Streamer/pages/StreamerDashboard'),
    meta: { title: 'Creator Home - Uplify' },
  },
  {
    path: '/campaigns',
    name: 'campaigns',
    redirect: { name: 'campaigns-livestream' },
  },
  {
    path: '/campaigns/livestream',
    name: 'campaigns-livestream',
    component: () => import('Streamer/pages/StreamerCampaigns'),
    meta: { title: 'Creator Campaigns - Uplify' },
  },
  {
    path: '/campaigns/preroll',
    name: 'campaigns-preroll',
    component: () => import('Streamer/pages/StreamerCampaigns'),
    meta: { title: 'Creator Campaigns - Uplify' },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('Streamer/pages/StreamerWallet'),
    meta: { title: 'Creator Wallet - Uplify' },
  },
  {
    path: '/widget',
    name: 'widget',
    component: () => import('Streamer/pages/StreamerWidget'),
    meta: { title: 'Creator Widget - Uplify' },
  },
  {
    path: '/referrals',
    name: 'referrals',
    component: () => import('Streamer/pages/StreamerReferrals'),
    meta: { title: 'Creator Referrals - Uplify' },
  },
  // {
  //   path: '/extension',
  //   name: 'extension',
  //   component: () => import('Streamer/pages/StreamerExtension'),
  //   meta: { title: 'Creator Extension - Uplify' },
  // },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('Streamer/pages/StreamerProfile'),
    meta: { title: 'Creator Profile - Uplify' },
  },
  {
    path: '/link',
    name: 'freemium',
    component: () => import('Streamer/pages/StreamerFreemium'),
    meta: { title: 'Creator Freemium - Uplify' },
  },
  {
    path: '/debug/:apiVersion?/:slug?',
    name: 'debug',
    component: () => import('views/WidgetOld/Debug'),
    meta: { title: 'Debug - Uplify' },
  },
  {
    path: '/auth/*',
    name: 'streamer-auth',
    redirect: { name: 'dashboard' },
  },
  {
    path: '/',
    name: 'streamer-main',
    redirect: { name: 'dashboard' },
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404'),
    meta: { title: '404 - Uplify' },
  },
]
