import BaseModel from '@/models/BaseModel'

export default class Wallet extends BaseModel {
  static routes = {
    fetch: 'streamer/wallet',
  }

  static schema = {
    balance: 0.00,
    amount_payable: 0.00,
    date_payable: '',
    methods: [],
    audited_earnings: 0.00,
    estimated_earnings: 0.00,
    currency: '',
  }

  static aliases = {
    amount_payable: 'amountPayable',
    date_payable: 'datePayable',
    audited_earnings: 'auditedEarnings',
    estimated_earnings: 'estimatedEarnings',
  }
}
