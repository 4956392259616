import { Api } from 'lib/api/instance'

import Wallet from '@/models/Wallet'

export default {
  namespaced: true,
  state: {
    wallet: new Wallet(),
    transactions: {
      bootstrapped: false,
      perPage: 10,
      page: 1,
      total: 10,
      items: [],
    },
    panels: null,
    referral: null,
    referralProgress: null,
    referralAvailable: null,
    referrals: null,
    extension: {
      blocks: {},
      form: [],
      dictionary: {},
    },
    checkList: {
      isLoadingData: false,
      data: null,
    },
    loyalty: {
      isLoadingData: false,
      visible: false,
      data: null,
      selectedLevel: null,
    },
  },
  mutations: {
    setTransactions (state, payload) {
      state.transactions.items = payload.data
      state.transactions.total = payload.total
      state.transactions.perPage = payload.per_page
      state.transactions.bootstrapped = true
    },
    setChecklistFetchingStatus (state, payload) {
      state.checkList.isLoadingData = payload
    },
    setCheckListData (state, payload) {
      state.checkList.data = payload
    },
    setIsCheckListClosed (state, payload) {
      state.checkList.data.close = payload
    },
    setTransactionsPage (state, payload) {
      state.transactions.page = payload
    },
    setLoyaltyFetchingStatus (state, payload) {
      state.loyalty.isLoadingData = payload
    },
    setLoyaltytData (state, payload) {
      state.loyalty.visible = payload.visible
      if (payload.visible) {
        state.loyalty.data = payload.data
        state.loyalty.selectedLevel = payload.data?.current.level === 0 ? 1 : payload.data?.current.level
      }
    },
    setReferral (state, payload) {
      state.referral = {
        ...state.referral,
        ...payload,
      }
    },
    setReferralAvailable (state, payload) {
      state.referralAvailable = payload
    },
    setReferrals (state, payload) {
      state.referrals = {
        total: payload.total,
        perPage: payload.per_page,
        items: payload.data,
      }
    },
    setPanels (state, payload) {
      state.panels = payload
    },
    setExtension (state, payload) {
      state.extension = {
        form: payload.form || state.extension.form,
        blocks: payload.data || state.extension.data,
        dictionary: payload.dictionary || state.extension.dictionary,
      }
    },
    setSelectedLevel (state, payload) {
      state.loyalty.selectedLevel = payload
    },
    setReferralProgress (state, payload) {
      state.referralProgress = payload
    },
  },
  actions: {
    async fetchTransactions ({ commit, state }) {
      const data = { page: state.transactions.page }
      const res = await Api.get('streamer/wallet/transactions', data)
      commit('setTransactions', res)
    },
    setTransactionsPage ({ commit, dispatch }, page) {
      commit('setTransactionsPage', page)
      dispatch('fetchTransactions')
    },
    async fetchChecklist ({ commit }) {
      commit('setChecklistFetchingStatus', true)
      const res = await Api.get('streamer/dashboard/check-list')
      commit('setCheckListData', res.data)
      commit('setChecklistFetchingStatus', false)
    },
    async closeChecklist ({ commit }) {
      commit('setIsCheckListClosed', true)
      await Api.post('streamer/dashboard/check-list/close')
    },
    async fetchLoyaltyProgram ({ commit }) {
      commit('setLoyaltyFetchingStatus', true)
      const res = await Api.get('streamer/tier')
      commit('setLoyaltytData', res)
      commit('setLoyaltyFetchingStatus', false)
    },
    async fetchReferral ({ commit }) {
      const locale = localStorage.getItem('locale')
      try {
        const res = await Api.get(`streamer/referral?locale=${locale}`, undefined, { showMessage: false })
        commit('setReferralAvailable', true)
        if (res.status && res.data) {
          commit('setReferral', res.data)
        }
        return res
      }
      catch (error) {
        commit('setReferralAvailable', false)
      }
    },
    async fetchReferrals ({ commit }, page) {
      const res = await Api.get(`streamer/referral/history?page=${page}`)
      commit('setReferrals', res)
      return res
    },
    async fetchPanels ({ commit }) {
      const locale = localStorage.getItem('locale')
      const res = await Api.get(`streamer/referral/panels?locale=${locale}`)
      commit('setPanels', res.data)
      return res
    },
    async fetchExtension ({ commit }) {
      const res = await Api.get('streamer/extension/twitch')
      commit('setExtension', res)
    },
    async updateExtension (data) {
      await Api.post('streamer/extension/twitch/save', data)
    },
    async updatePromotion ({ commit }, promotion) {
      await Api.post('streamer/referral/save', { promotion })
      commit('setReferral', { promotion })
    },
    setSelectedLevel ({ commit }, level) {
      commit('setSelectedLevel', level)
    },
    async fetchReferralProgress ({ commit }) {
      const res = await Api.get('streamer/referral/progress', null, { showMessage: false })
      commit('setReferralProgress', res.data)
    },
  },
  getters: {
    wallet: ({ wallet }) => wallet,
    payoutMethod: ({ wallet }) => wallet.methods.find((item) => item.visible),
    transactions: ({ transactions }) => transactions,
    referral: ({ referral }) => referral,
    referralProgress: ({ referralProgress }) => referralProgress,
    referralPanels: ({ panels }) => panels,
    referralToken: ({ referral }) => referral?.link || '',
    referralStatistic: ({ referral }) => ({
      amount: referral?.amount || 0,
      currency: referral?.currency,
      invited: referral?.invited || 0,
    }),
    checkList: ({ checkList }) => checkList,
    referralAvailable: ({ referralAvailable }) => referralAvailable,
    referrals: ({ referrals }) => referrals.items,
    referralsHistory: ({ referrals }) => referrals,
    consts: ({ referral }) => referral?.const || {},
    promotion: ({ referral }) => referral?.promotion || false,
    extension: ({ extension }) => extension,
    loyalty: ({ loyalty }) => loyalty,
    currentLevel: ({ loyalty }) => loyalty.data?.current.level,
  },
}
